import { observer } from "mobx-react-lite";
import stashedWalletIcon from "@/assets/icons/wallet-icons/stashed-wallet-icon.png";
import okxWalletIcon from "@/assets/icons/wallet-icons/okx-wallet-icon.png";
import styles from "./styles.module.sass";
import { externalLink, isIOS } from "@/utils/handleExpandPage";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import walletBg from "@/assets/capybara-images/walletBg.png";
import rootStore, { walletStore } from "@/store";
import { useNavigate } from "react-router-dom";
import { backend } from "@/services";
import { connectWallet } from "@/utils/createWallet";
import { OKXSuiProvider } from "@okxconnect/sui-provider";
import { Dialog } from "@/components/Dialog";
import { useState } from "react";

let interval: NodeJS.Timeout;

const wallets = [
  {
    id: 0,
    title: "Connect Stashed Wallet",
    icon: stashedWalletIcon,
  },
  {
    id: 1,
    title: "Connect OKX SUI Wallet",
    icon: okxWalletIcon,
  },
];

const SelectWallet = observer(() => {
  const [isOKXConnected, setIsOKXConnected] = useState(false);
  const navigate = useNavigate();

  const focus = () => {
    backend
      .getContext("trust_wallet")
      .then(() => {
        clearInterval(interval);
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClick = (walletId: number) => {
    if (walletId === 0) {
      externalLink(rootStore.isEarly ? "/authWallets" : "/authStashed", {
        token: localStorage.getItem("token") ?? "",
      });

      interval = setInterval(() => {
        console.log(123);
        focus();
      }, 1000);
    }

    if (walletId === 1) {
      rootStore.setLoading(true);
      const OKXUniversalUIProvider = connectWallet();

      OKXUniversalUIProvider.then((result) => {
        const sui = new OKXSuiProvider(result);

        result.onModalStateChange((state: { status: string }) => {
          if (state.status === "closed") {
            rootStore.setLoading(false);
          }
        });

        result
          .openModal({
            namespaces: {
              sui: {
                chains: ["sui:mainnet"],
              },
            },
          })
          .then(() => {
            if (result?.connected()) {
              const data = sui?.getAccount();
              backend.sendWallet(data?.address as string, "OKX");
              walletStore.setOKXProviders(new OKXSuiProvider(result), result);
              walletStore.setWallet("OKX", data?.address as string);
              rootStore.setLoading(false);

              if (rootStore.isEarly) {
                if (isIOS) {
                  externalLink("/authWallets", {
                    isOkx: "true",
                    token: localStorage.getItem("token") ?? "",
                    receiverAddress: data?.address as string,
                  });
                  setTimeout(() => {
                    navigate("/");
                  }, 1000);
                } else {
                  setIsOKXConnected(true);
                }
              } else {
                navigate("/");
              }
            }
          });
      });
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.walletsWrapper}>
        {wallets.map((wallet) => (
          <div
            onClick={() => handleClick(wallet.id)}
            className={styles.walletItem}
          >
            <img src={wallet.icon} alt={wallet.title} />
            <p>{wallet.title}</p>
          </div>
        ))}
      </div>
      <Dialog
        modalWallpaper={walletBg}
        isOpen={isOKXConnected}
        onClose={() => {
          setIsOKXConnected(false);
          externalLink("/authWallets", {
            isOkx: "true",
            token: localStorage.getItem("token") ?? "",
            receiverAddress: walletStore.address ?? "",
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }}
        onAction={() => {
          setIsOKXConnected(false);
          externalLink("/authWallets", {
            isOkx: "true",
            token: localStorage.getItem("token") ?? "",
            receiverAddress: walletStore.address ?? "",
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }}
        title={"OKX wallet successfully connected!"}
        text={"Click continue to proceed"}
        modalTitleColor={"#C0C3FF"}
        modalIcon={iosWalletImg}
        buttonText={"Continue"}
      />
    </div>
  );
});

export default SelectWallet;
