import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";
import Info from "@/assets/layout/rating/info.svg?react";
import { observer } from "mobx-react-lite";

import NftBorder from "@/assets/capybara-images/nftBorder.png";
import clsx from "clsx";
import { Button } from "../Button";
import SuiImg from "@/assets/capybara-images/sui-80.png";
import { useNavigate } from "react-router-dom";
import { isProduction, userId } from "@/utils";
import { SignType } from "../CreateWallet/components/QueryWrapper";
import { useEffect, useState } from "react";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import { buildCheckinTx, buildMintNftTx } from "@/utils/mintNft";

import {
  apiProduction,
  apiStage,
  capybaraLevels,
  isProdUrl,
  PACKAGE_ADDRESS,
  REGISTRY_ADDRESS,
  TREASURY_ADDRESS,
} from "@/constants";
import ky from "ky";
import { handleClose } from "../CreateWallet/walletUtils";
import SentryObject from "@/utils/sentry";
import rootStore, { gameStore, leagueStore, walletStore } from "@/store";

const handleSuccess = (
  result: {
    digest: string;
  },
  setIsLoader: (value: boolean) => void,
  setIsError: (value: boolean) => void,
  base: string,
  userId: string,
  token: string,
  isUpdate?: boolean
) => {
  if (result.digest) {
    ky.post(base + `/api/nft/${isUpdate ? "update_tx" : "mint_tx"}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      json: {
        tx_digest: result.digest,
        user_id: userId,
      },
    })
      .then((result) => result.json() as Promise<{ ok: boolean; err: string }>)
      .then((data) => {
        if (data.ok) {
          setIsLoader(false);
          window.location.href = "/";
        } else {
          setIsLoader(false);
          setIsError(true);
          SentryObject?.captureException(data.err, {
            extra: { token, userId, isUpdate },
          });
        }
      });
  }
};

export const MintingNftOKX = observer(() => {
  const [capybaraNftStatus, setCapybaraNftStatus] = useState<
    "MINTING" | "UPDATABLE"
  >("MINTING");
  const navigate = useNavigate();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sign, setSign] = useState<SignType | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const base = isProdUrl ? apiProduction : apiStage;

  const transfer = ({
    sign,
    reciever,
    token,
    userId,
    isUpdate,
    nftId,
  }: {
    sign: SignType;
    reciever: string;
    token: string;
    userId: string;
    isUpdate?: boolean;
    nftId?: string;
  }) => {
    setIsLoader(true);
    isError && setIsError(false);

    if (!transacrionInWork) {
      if (sign.ok) {
        const signature = new Uint8Array(Buffer.from(sign.signature, "base64"));
        console.log(sign, nftId, signature, "sign");

        let nftTx;

        if (isUpdate && nftId) {
          nftTx = buildCheckinTx({
            functionParams: {
              nftAddress: nftId,
              treasury: TREASURY_ADDRESS,
              registry: REGISTRY_ADDRESS,
              fee: 0,
              validUntil: sign.valid_until,
              points: sign.balance,
              league: sign.league,
              signature: signature,
            },
            packageAddress: PACKAGE_ADDRESS,
            userAddress: reciever,
          });
        } else {
          nftTx = buildMintNftTx({
            packageAddress: PACKAGE_ADDRESS,
            sigValidUntil: sign.valid_until,
            points: sign.balance,
            league: sign.league,
            signature: new Uint8Array(Buffer.from(sign.signature, "base64")),
            registryAddress: REGISTRY_ADDRESS,
          });
        }

        const input = {
          transactionBlock: nftTx,
          chain: "sui:mainnet",
          options: {
            showEffects: true,
          },
        };
        rootStore.setLoading(false);

        walletStore.OKXSuiProvider?.signAndExecuteTransaction(input)
          .then((r) => {
            const result = r as {
              confirmedLocalExecution: boolean;
              digest: string;
            };
            console.log(result, "result");

            handleSuccess(
              { digest: result.digest },
              setIsLoader,
              setIsError,
              base,
              userId,
              token,
              isUpdate
            );
          })
          .catch((error) => {
            console.log(error, "error");
            setIsError(true);
          });
      }

      setTransacrionInWork(true);
    }
  };

  useEffect(() => {
    if (
      walletStore.nft &&
      walletStore.nft.updatedAt &&
      (isProduction
        ? walletStore.nft.updatedAt + 24 * 3600 < Date.now() / 1000
        : walletStore.nft.updatedAt + 60 < Date.now() / 1000)
    ) {
      setCapybaraNftStatus("UPDATABLE");
    }
  }, []);

  useEffect(() => {
    setIsLoader(true);
    ky.get(base + `/api/nft/daily_sign`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((result) => result.json() as Promise<SignType>)
      .then((data) => {
        setSign(data);
        setIsLoader(false);
      });
  }, []);

  return (
    <>
      {isLoader && <Loader />}

      <div className={styles.capybaraContainer}>
        <div
          className={clsx(styles.capybaraCard, {
            [styles.minted]: capybaraNftStatus === "UPDATABLE",
            [styles.minting]: capybaraNftStatus === "MINTING",
          })}
        >
          <div className={styles.additionalDataText}>
            {capybaraNftStatus === "MINTING" && (
              <p>
                Player Card
                <br /> NFT
              </p>
            )}
          </div>
          <img className={styles.nftBackground} src={NftBorder} />
          <img
            draggable="false"
            className={clsx(styles.capybaraImg, {
              [styles.mintingImg]: capybaraNftStatus === "MINTING",
            })}
            src={capybaraLevels[+leagueStore.level].img}
            alt="state"
          />

          <div
            className={clsx(styles.capybaraAttributesWrapper, {
              [styles.capybaraAttributesUpdateble]:
                capybaraNftStatus === "UPDATABLE",
              [styles.capybaraAttributesMintable]:
                capybaraNftStatus === "MINTING",
            })}
          >
            <div className={styles.capybaraAttributesContainer}>
              <p className={clsx(styles.capybaraAttributesTitle)}>
                Attributes:
              </p>
              <div className={styles.capybaraAttributesBlock}>
                <p>
                  Coins Earned:
                  <Coin width={12} height={12} /> {gameStore.balance}
                </p>
                <p> League: {leagueStore.leagueName} </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.feeContainer}>
          <div className={styles.feeInfo}>
            <p>Transaction Fee</p>
            <p className={styles.amount}>0.01 SUI</p>
          </div>
          <Info />
        </div>
        {capybaraNftStatus === "MINTING" && (
          <Button
            onClick={() => {
              sign &&
                transfer({
                  sign,
                  reciever: walletStore.address ?? "",
                  token: localStorage.getItem("token") ?? "",
                  userId: userId?.toString() ?? "",
                });
            }}
            block={true}
            label={"Mint Player Card NFT"}
          />
        )}

        {capybaraNftStatus === "UPDATABLE" && (
          <Button
            onClick={() => {
              sign &&
                transfer({
                  sign,
                  reciever: walletStore.address ?? "",
                  token: localStorage.getItem("token") ?? "",
                  userId: userId?.toString() ?? "",
                  nftId: walletStore.nft?.address,
                  isUpdate: true,
                });
            }}
            block={true}
            label={"Update Attributes"}
          />
        )}

        <Dialog
          modalWallpaper={""}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          onAction={() => navigate("/wallet")}
          title={"Not enough SUI"}
          text={`You don’t have enough SUI in your wallet to cover the Transaction Fee.
           Please Buy SUI or deposit from external wallet.`}
          modalTitleColor={"#C0E6FF;"}
          modalIcon={<img width={80} height={80} src={SuiImg} />}
          buttonText={"Buy"}
          additionalbuttonText="Deposit"
          handleAdditionalAction={() => navigate("/wallet?receive=true")}
        />

        <Dialog
          modalWallpaper={""}
          isOpen={isError}
          onClose={handleClose}
          onAction={() => handleClose()}
          title={"Something Went Wrong"}
          text={"Please click the button below to try again."}
          modalTitleColor={"#C0C3FF"}
          modalIcon={<img width={80} height={80} src={SuiImg} />}
          buttonText={"Return to Capybara"}
        />
      </div>
    </>
  );
});
