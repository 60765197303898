import { SignType } from "@/components/CreateWallet/components/QueryWrapper";
import {
  apiProduction,
  apiStage,
  DataStorage_TEST,
  EMPTY_TP,
  isProdUrl,
  Package_TEST,
} from "@/constants";
import rootStore, { gameStore, lootboxesStore, walletStore } from "@/store";
import { externalLink } from "@/utils/handleExpandPage";
import { buildMintLootBoxTx, buildOpenLootBoxTx } from "@/utils/mintNft";
import { buyMoneyBagState } from "@/views/BuyMoneyBag/state";
import { useSuiClient } from "@mysten/dapp-kit";
import { Secp256k1Keypair } from "@mysten/sui/keypairs/secp256k1";
import { KioskClient, Network } from "@mysten/kiosk";
import ky from "ky";
import { action, observable } from "mobx";

const moneyBagState = observable({
  isMoneyBagOpen: false,
});

const setMoneyBag = action((state: boolean) => {
  moneyBagState.isMoneyBagOpen = state;
});

const base = isProdUrl ? apiProduction : apiStage;

export const useMoneyBag = () => {
  const client = useSuiClient();

  const kioskClient = new KioskClient({
    client,
    network: Network.MAINNET,
  });

  const buyMoneyBag = () => {
    if (gameStore.balance < 5000000 * buyMoneyBagState.amount) {
      buyMoneyBagState.isNotEnoughCoins = true;
      return;
    }

    if (walletStore.walletType === "OKX") {
      rootStore.setLoading(true);
      ky.get(base + `/api/nft/lootbox/sign?count=${buyMoneyBagState.amount}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((result) => result.json() as Promise<SignType>)
        .then((data) => {
          if (data.ok) {
            gameStore.addBalance(-5000000);
            const uid = BigInt(data.random_id);
            const signature = new Uint8Array(
              Buffer.from(data.signature, "base64")
            );

            buildMintLootBoxTx({
              packageAddress: Package_TEST,
              uid,
              dataStorage: DataStorage_TEST,
              signature,
              count: buyMoneyBagState.amount,
              validUntil: data.valid_until,
              kioskClient,
              kioskOwnerCap: walletStore.address ?? "",
            }).then((mintLootboxTx) => {
              const input = {
                transactionBlock: mintLootboxTx,
                chain: "sui:mainnet",
                options: {
                  showEffects: true,
                },
              };
              rootStore.setLoading(false);

              walletStore.OKXSuiProvider?.signAndExecuteTransaction(input)
                .then((result) => {
                  const handledResult = result as {
                    digest: string;
                  };

                  ky.post(base + `/api/nft/lootbox/mint_tx`, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    json: {
                      tx_digest: handledResult.digest,
                    },
                  });
                  lootboxesStore.syncLootboxes(walletStore.address ?? "");
                  setMoneyBag(true);
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          }
        })
        .catch(() => {
          rootStore.setLoading(false);
        });
    } else {
      closeMoneyBag();
      externalLink("/MintMoneyBag", {
        count: buyMoneyBagState.amount.toString(),
        token: localStorage.getItem(`token`) ?? "",
        address: walletStore.address ?? "",
      });
    }
  };

  const buyMoneyBagSimulation = async () => {
    rootStore.setLoading(true);
    const result = await ky.get(
      base + `/api/nft/lootbox/sign?count=${buyMoneyBagState.amount}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = (await result.json()) as SignType;

    if (!data.ok) {
      return;
    }

    const uid = BigInt(data.random_id);
    const signature = new Uint8Array(Buffer.from(data.signature, "base64"));

    const mintLootboxTx = await buildMintLootBoxTx({
      packageAddress: Package_TEST,
      uid,
      dataStorage: DataStorage_TEST,
      signature,
      count: buyMoneyBagState.amount,
      validUntil: data.valid_until,
      kioskClient,
      kioskOwnerCap: walletStore.address ?? "",
    });

    mintLootboxTx.setSender(walletStore.address ?? "");
    const signedTx = await mintLootboxTx.sign({
      signer: Secp256k1Keypair.generate(),
      client,
    });
    const dryResult = await client.dryRunTransactionBlock({
      transactionBlock: signedTx.bytes,
    });

    if (dryResult.effects.status.status !== "failure") {
      const input = {
        transactionBlock: mintLootboxTx,
        chain: "sui:mainnet",
        options: {
          showEffects: true,
        },
      };
      walletStore.OKXSuiProvider?.signAndExecuteTransaction(input)
        .then((result) => {
          const handledResult = result as {
            digest: string;
          };

          ky.post(base + `/api/nft/lootbox/mint_tx`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            json: {
              tx_digest: handledResult.digest,
            },
          });
          lootboxesStore.syncLootboxes(walletStore.address ?? "");
          setMoneyBag(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("time", Date.now());
      console.log("sign data", data);
      console.log("dryResult", dryResult);
    }

    rootStore.setLoading(false);
  };

  const openMoneyBag = () => {
    moneyBagState.isMoneyBagOpen = false;
    if (walletStore.walletType === "OKX" && walletStore.address) {
      buildOpenLootBoxTx({
        transferLootboxId: lootboxesStore.getLootBoxId(),
        dataStorage: DataStorage_TEST,
        packageAddress: Package_TEST,
        emptyTp: EMPTY_TP,
        userAddress: walletStore.address,
        kioskClient: kioskClient,
      }).then((openLootboxTx) => {
        const input = {
          transactionBlock: openLootboxTx,
          chain: "sui:mainnet",
          options: {
            showEffects: true,
          },
        };

        walletStore.OKXSuiProvider?.signAndExecuteTransaction(input)
          .then((result) => {
            const handledResult = result as unknown as {
              digest: string;
            };

            ky.post(base + `/api/nft/lootbox/open_tx`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              json: {
                tx_digest: handledResult.digest,
              },
            }).then((result) => {
              result.json().then((result) => {
                const handledResult = result as {
                  ok: boolean;
                  reward: number;
                };
                setMoneyBag(false);
                buyMoneyBagState.isMoneyBagOpened = true;
                lootboxesStore.syncLootboxes(
                  walletStore.address ?? "",
                  handledResult.reward
                );
              });
            });
          })
          .catch(() => {});
      });
    } else if (walletStore.walletType === "STASHED") {
      externalLink("/OpenMoneyBag", {
        moneyBagId: lootboxesStore.getLootBoxId(),
        token: localStorage.getItem(`token`) ?? "",
        address: walletStore.address ?? "",
      });
    }
  };

  const openMoneyBagModal = () => {
    if (lootboxesStore.bags.length > 0) {
      setMoneyBag(true);
    }
  };

  const closeMoneyBag = () => {
    setMoneyBag(false);
  };

  return {
    openMoneyBag,
    buyMoneyBagSimulation,
    closeMoneyBag,
    openMoneyBagModal,
    buyMoneyBag,
    moneyBagState,
  };
};
