import { action, computed, observable } from "mobx";

import img from "@/assets/capybara-images/capynft.png";
import { getFullnodeUrl, SuiClient } from "@mysten/sui/client";
import rootStore from ".";
import { getAllKiosksAndObjects } from "@/utils/mintNft";
import { KioskClient, Network } from "@mysten/kiosk";

export type Bag = {
  objectId: string;
  version: string;
  digest: string;
  type: string;
  display: {
    data: {
      description: string;
      image_url: string;
      name: string;
      thumbnail_url: string;
    };
    error: string | null;
  };
  content: {
    dataType: string;
    type: string;
    hasPublicTransfer: boolean;
    fields: {
      id: {
        id: string;
      };
      idx: string;
    };
  };
};

interface State {
  bags: Bag[];
  recieved: {
    modal: boolean;
    data: { name: string; img: string };
  };
}

export default class LootboxesStore {
  @observable private accessor lootboxStep: number = 0;
  @observable public accessor openBagsCount: number = 0;
  @observable public accessor promoModal: boolean = false;
  @observable public accessor lastReward: number = 0;
  @observable private accessor state: State = {
    bags: [],
    recieved: {
      modal: false,
      data: { name: "", img },
    },
  };

  constructor() {
    const nextShow = localStorage?.getItem("nextShowPromo1");

    if (!nextShow || +nextShow < Date.now()) {
      this.promoModal = true;
    }
  }

  @computed public get receiveModal() {
    return this.state.recieved.modal;
  }

  @computed public get receivedData() {
    return this.state.recieved.data;
  }

  @computed public get canBuy() {
    return true;
  }

  @computed public get hasLootboxes() {
    return this.canBuy && !!this.state.bags.length;
  }

  @computed public get bags() {
    return this.state.bags;
  }

  @action.bound setOpenBagsCount(count: number) {
    this.openBagsCount = count;
  }

  @action.bound getLootBoxId() {
    const currentStep = this.lootboxStep;
    this.lootboxStep += 1;
    return this.state.bags[currentStep].objectId;
  }

  @action.bound setShowPromo() {
    this.promoModal = false;
    localStorage?.setItem(
      "nextShowPromo1",
      String(Date.now() + 1000 * 60 * 60 * 24)
    );
  }

  @action.bound syncLootboxes(address: string, number?: number) {
    const client = new SuiClient({
      url: getFullnodeUrl("mainnet"),
    });

    if (number) {
      this.lastReward = number;
    }

    const kioskClient = new KioskClient({
      client,
      network: Network.MAINNET,
    });

    getAllKiosksAndObjects({ kioskClient, owner: address }).then((r) => {
      const lootboxes = [] as Bag[];
      console.log(r);
      r.forEach((result) => {
        result[1].forEach((item) => {
          lootboxes.push(item.data as Bag);
        });
      });
      rootStore.updateNftData();
      this.addBags(lootboxes);
    });

    // client
    //   .getOwnedObjects({
    //     owner: address ?? "",
    //     options: {
    //       showType: true, // Показывать тип объекта
    //       showContent: true, // Показывать содержимое объекта
    //       showDisplay: true, // Включать метаданные отображения
    //     },
    //   })
    //   .then((r) => {
    //     rootStore.updateNftData();
    //

    //
    //   });
  }

  @action.bound addBags(bags: Bag[]) {
    this.lootboxStep = 0;
    this.state.bags = bags;
  }

  @action.bound openReceiveModal(state: boolean) {
    this.state.recieved.modal = state;
  }
}
