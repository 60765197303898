import { useEffect, useState } from "react";
import styles from "./styles.module.sass";
import walletImg from "@/assets/capybara-images/walletImg.png";
import stashedWalletIcon from "@/assets/icons/wallet-icons/stashed-wallet-icon.png";
import stashedWalletBg from "@/assets/icons/wallet-icons/stashedWalletBg.png";
import { Dialog } from "../Dialog";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "../Badge";
import Coin from "@/assets/capybara-images/coin.svg?react";
import rootStore from "@/store";

interface CreateWalletButtonProps {
  backgroundColor: string;
}

export const CreateWallet = observer(
  ({ backgroundColor }: CreateWalletButtonProps) => {
    const location = useLocation();

    const navigate = useNavigate();
    const [isOpenTransfer, setIsOpenTransfer] = useState(false);

    useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      if (query.has("fromTask")) {
        navigate("/");
        setIsOpenTransfer(true);
      }
    }, [location]);

    const handleAuth = () => {
      navigate("/selectWallet");
    };

    return (
      <div className={styles.walletWrapper}>
        <div
          onClick={() => setIsOpenTransfer(true)}
          style={{
            background: backgroundColor,
          }}
          className={styles.wallet}
        >
          <img src={walletImg} alt="walletImg" />
          <p>{"Create a Wallet"}</p>
        </div>

        <Dialog
          modalWallpaper={stashedWalletBg}
          isOpen={isOpenTransfer || rootStore?.showWallet}
          onClose={() => {
            rootStore.setShowWallet(false);
            setIsOpenTransfer(false);
          }}
          onAction={() => handleAuth()}
          title={
            <div className={styles.modalTitle}>
              <Badge size="m">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  +200,000
                </p>{" "}
                <Coin width={16} height={16} />
              </Badge>
              <h2>Create a Wallet</h2>
            </div>
          }
          text={
            <div className={styles.modalTextBody}>
              <p
                style={{
                  fontWeight: 600,
                }}
              >
                Get 200,000 Bonus Coins!
              </p>
              <p>
                {" "}
                <span
                  style={{
                    fontWeight: 400,
                  }}
                >
                  The BIG EVENT is almost here!
                </span>{" "}
                Set up your wallet and mint your Player Card NFT now to secure
                your rewards. Don’t miss out! 💰✨
              </p>
            </div>
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={
            <img
              src={stashedWalletIcon}
              width={90}
              height={90}
              alt="stashedWalletIcon"
            />
          }
          buttonText={"Create a Wallet"}
        />
      </div>
    );
  }
);
