import React, { useState } from "react";
import styles from "./Dialog.module.sass";
import { Button } from "@/components/Button";
import ModalCloser from "@/assets/capybara-images/modalCloser.svg?react";
import { renderTextWithLineBreaks } from "@/utils/renderTextWithLineBreaks";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";

interface DialogModalProps {
  title: string | JSX.Element;
  text: string | JSX.Element;
  onClose?: () => void;
  onAction?: () => void;
  additionalbuttonText?: string;
  handleAdditionalAction?: () => void;
  modalTitleColor: string;
  modalIcon: string | JSX.Element;
  modalWallpaper: string;
  isOpen?: boolean;
  hint?: string;
  buttonText?: string;
}

export const Dialog: React.FC<DialogModalProps> = observer(
  ({
    isOpen = false,
    onClose,
    onAction,
    title,
    additionalbuttonText,
    handleAdditionalAction,
    modalTitleColor,
    modalIcon,
    modalWallpaper,
    text,
    hint,
    buttonText,
  }) => {
    const [closing, setClosing] = useState(false);

    const handleClose = () => {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
        onClose && onClose();
      }, 300);
    };

    const handleAction = () => {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
        onAction && onAction();
      }, 300);
    };

    return (
      <ReactModal
        ariaHideApp={false}
        overlayClassName={styles.modalOverlay}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleClose}
        className={`${styles.modalContainer} ${
          closing ? styles.modalClose : ""
        }`}
        isOpen={isOpen}
      >
        <div className={styles.modalContentWrapper}>
          <div
            className={styles.modalContent}
            style={{
              backgroundImage: `url(${modalWallpaper})`,
            }}
          >
            <ModalCloser className={styles.modalCloser} onClick={handleClose} />
            {typeof modalIcon === "string" ? (
              <img className={styles.modalImg} src={modalIcon} alt="icon" />
            ) : (
              modalIcon
            )}
            <div className={styles.textContainer}>
              {typeof title === "string" ? (
                <h2
                  style={{
                    color: modalTitleColor,
                  }}
                  className={styles.modalTitle}
                >
                  {renderTextWithLineBreaks(title)}
                </h2>
              ) : (
                title
              )}
              <span>
                <p className={styles.modalText}>{text}</p>
                <p className={styles.modalText}>{hint}</p>
              </span>
            </div>
            <Button
              onClick={handleAction}
              label={buttonText ?? ""}
              styleType="primary"
              block={true}
            />
            {additionalbuttonText && (
              <Button
                onClick={handleAdditionalAction}
                label={additionalbuttonText ?? ""}
                styleType="outline"
                block={true}
              />
            )}
          </div>
        </div>
      </ReactModal>
    );
  }
);
