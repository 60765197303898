// QueryWrapper.tsx
import { useEffect, useState, ReactNode } from "react";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import { handleClose } from "../walletUtils";
import ky from "ky";
import { apiProduction, apiStage, isProdUrl } from "@/constants";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";

export type SignType = {
  ok: boolean;
  signature: string;
  random_id: string;
  valid_until: number;
  balance: number;
  league: number;
  wallet: string;
};

interface QueryWrapperProps<T> {
  children: (props: {
    queryParams: T;
    handleClose: () => void;
    sign?: SignType;
  }) => ReactNode;
  requiredParams?: (keyof T)[];
  nonRequiredParams?: (keyof T)[];
  isSign?: boolean;
  signType?: "MINT" | "LOOTBOX";
}

export const QueryWrapper = <T extends Record<string, string>>({
  children,
  requiredParams = [],
  nonRequiredParams = [],
  isSign,
  signType = "MINT",
}: QueryWrapperProps<T>) => {
  const [sign, setSign] = useState<SignType | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [signStatus, setSignStatus] = useState<number | undefined>();
  const [isLoader, setIsLoader] = useState(false);
  const currentAccount = useCurrentAccount();
  const [queryParams, setQueryParams] = useState<Partial<T>>({});
  const [isValid, setIsValid] = useState(true);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(false);
  const base = isProdUrl ? apiProduction : apiStage;
  const [signRequetInWork, setSignRequetInWork] = useState(false);
  useEffect(() => {
    const address = localStorage.getItem("stashed:recentAddress");
    const walletConnectionInfo = localStorage.getItem(
      "sui-dapp-kit:wallet-connection-info"
    );
    const lastConectTime = localStorage.getItem("lastConnectTime");
    const sessionIsAlive = Date.now() - Number(lastConectTime) < 1000 * 60 * 60;

    if (address && walletConnectionInfo && sessionIsAlive) {
      setStashedWalletIsOpen(false);
    } else {
      setStashedWalletIsOpen(true);
      localStorage.removeItem("stashed:recentAddress");
      localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
      localStorage.removeItem("lastConnectTime");
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (isSign && !signRequetInWork) {
      console.log(signRequetInWork)
      setSignRequetInWork(true);
      if (signType === "MINT") {
        setIsLoader(true);
        ky.get(base + `/api/nft/daily_sign`, {
          headers: {
            Authorization: `Bearer ${urlParams.get("token")}`,
          },
        })
          .then((result) => result.json() as Promise<SignType>)
          .then((data) => {
            setSign(data);
            setIsLoader(false);
          })
          .catch((err) => {
            err.response
              .json()
              .then((data: { ok: boolean; reward: number; err?: string }) => {
                setError(data.err ?? "Unknown server error");
                setIsLoader(false);
              });
          });
      }
      if (signType === "LOOTBOX") {
        setIsLoader(true);

        ky.get(base + `/api/nft/lootbox/sign?count=${urlParams.get("count")}`, {
          headers: {
            Authorization: `Bearer ${urlParams.get("token")}`,
          },
        })
          .then((result) => result.json() as Promise<SignType>)
          .then((data) => {
            setSign(data);
            setIsLoader(false);
          })
          .catch((err) => {
            const status = err.response.status;
            setSignStatus(status);
            err.response
              .json()
              .then((data: { ok: boolean; reward: number; err?: string }) => {
                setError(data.err ?? "Unknown server error");
                setIsLoader(false);
              });
          });
      }
    }
  }, [isSign]);

  useEffect(() => {
    if (currentAccount?.address) {
      setStashedWalletIsOpen(false);
      const lastConnectTime = localStorage.getItem("lastConnectTime");
      if (!lastConnectTime) {
        localStorage.setItem("lastConnectTime", Date.now().toString());
      }
    }
  }, [currentAccount]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params: Partial<T> = {};

    requiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      } else {
        setIsValid(false);
      }
    });

    nonRequiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      }
    });

    setQueryParams(params);
  }, []);

  return isValid ? (
    <>
      {isLoader && <Loader />}
      <ConnectModal
        open={stashedWalletIsOpen && (isSign ? sign !== undefined : true)}
        trigger={<></>}
        onOpenChange={() => {}}
      />
      {signStatus === 410 ? (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={signStatus === 410}
          onClose={() => {
            setSignStatus(undefined);
            handleClose();
          }}
          onAction={() => {
            setSignStatus(undefined);
            window.location.href = "https://tradeport.xyz";
          }}
          handleAdditionalAction={() => {
            setError(undefined);
            window.location.href =
              "https://capybaraonsui.notion.site/3-How-to-buy-Money-Bag-NFT-559b3b29f9c840e58bdf25c0f549f837";
          }}
          title={"Minting Unavailable"}
          text={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <p>
                Your account isn't eligible to mint Money Bags. Eligibility is
                based on feeding your Capybara regularly, completing tasks, and
                inviting friends to join.
              </p>
              <p>
                In some cases, accounts may also be restricted due to suspicious
                activity.
              </p>
              <p>
                You can still purchase Money Bags from other users on the
                marketplace.
              </p>
              <p>
                Thank you for your understanding as we maintain fairness and
                reward active participation.
              </p>
            </span>
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Buy Money Bag"}
          additionalbuttonText={"How to Buy User Guide"}
        />
      ) : (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={error !== undefined}
          onClose={() => {
            setError(undefined);
            handleClose();
          }}
          onAction={() => {
            setError(undefined);
            handleClose();
          }}
          title={"Something went wrong"}
          text={
            "Looks like something went wrong with minting your money bag. " +
            error
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Return to Telegram"}
        />
      )}

      {!stashedWalletIsOpen &&
        (isSign ? sign : true) &&
        error === undefined &&
        children({ queryParams: queryParams as T, handleClose, sign })}
    </>
  ) : (
    "some params are missing"
  );
};
