import { action, flow, observable } from "mobx";
import type RootStore from "./RootStore";
import { backend } from "@/services";
import { balance, isProduction } from "@/utils";
import { Nft } from "@/services/types";
import { OKXSuiProvider } from "@okxconnect/sui-provider";

import { OKXUniversalConnectUI } from "@okxconnect/ui";
import ky from "ky";
import { lootboxesStore } from ".";

export default class WalletStore {
  rootStore: RootStore;
  @observable public accessor isCreated: boolean = false;
  @observable public accessor walletType: "OKX" | "STASHED" | null = null;
  @observable public accessor OKXSuiProvider: OKXSuiProvider | null = null;
  @observable
  public accessor OKXUniversalUIProvider: OKXUniversalConnectUI | null = null;
  @observable public accessor enokiAddress: string | null = "";
  @observable public accessor enokiHash: string | null = "";
  @observable public accessor address: string | null = "";
  @observable public accessor balance: string | number = "";
  @observable public accessor nft: Nft | null = null;
  @observable public accessor usdBalance: string | number = "";
  @observable public accessor currentCourse: string | number = "";
  @observable public accessor isBalanceLoading: boolean = false;
  @observable public accessor isNftCanCheckIn: boolean = false;
  @observable public accessor SimulationData: string | null = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action.bound chageCreateStatus(value: boolean) {
    this.isCreated = value;
  }

  @action.bound setCanCheckIn(value: boolean) {
    this.isNftCanCheckIn = value;
  }

  @action.bound setOKXProviders(
    OKXSuiProvider: OKXSuiProvider,
    OKXUniversalUIProvider: OKXUniversalConnectUI
  ) {
    this.OKXSuiProvider = OKXSuiProvider;
    this.OKXUniversalUIProvider = OKXUniversalUIProvider;
  }

  @action.bound setWallet(value?: "OKX" | "STASHED", address?: string) {
    this.address = address ?? "";
    this.walletType = value ?? null;

    if (address) {
      lootboxesStore.syncLootboxes(address);

      const rpcRequest = {
        jsonrpc: "2.0",
        method: "suix_getBalance",
        params: [address],
        id: 1,
      };

      ky.post("https://fullnode.mainnet.sui.io", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rpcRequest),
      }).then((resp) => {
        resp.json().then((result) => {
          const typedResult = result as {
            jsonrpc: string;
            result: { totalBalance: string };
            id: number;
          };

          this.setBalance(typedResult?.result.totalBalance);
        });
      });

      this.chageCreateStatus(true);
    }
  }

  @action.bound logout() {
    console.log("logout");
    backend.logoutWallet();
    if (this.walletType === "OKX") {
      this.OKXUniversalUIProvider?.disconnect();
    }
    this.setWallet();
    localStorage.removeItem("stashed:recentAddress");
    localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
    this.isCreated = false;
  }

  @action.bound setNftData(nft: Nft) {
    const startTime = nft?.updatedAt;
    const endTime = isProduction ? startTime + 24 * 3600 : startTime + 60;
    const now = Math.floor(Date.now() / 1000);
    const remainingSeconds = endTime - now;

    if (remainingSeconds <= 0) {
      this.setCanCheckIn(true);
    }

    this.nft = nft;
  }

  @action.bound setEnokiHash(value: string) {
    this.enokiHash = value;
  }

  @action.bound setBalance(value: string | number) {
    this.balance = value;
    this.getUsd();
  }

  @action.bound getUsd() {
    backend
      .getSuiCost()
      .then((res) => {
        this.currentCourse = res.data.rates.USD;
        this.usdBalance = Number(balance(this.balance)) * res.data.rates.USD;
      })
      .catch(() => {
        this.currentCourse = 0;
        this.usdBalance = 0;
      });
  }

  @flow.bound *getUsdBalance() {
    yield;
  }
}
