import { action, observable } from "mobx";

export const buyMoneyBagState = observable({
  bagTip: false,
  isNotEnoughCoins: false,
  isMoneyBagOpened: false,
  amount: 1,
});

export const setBagTip = action((state: boolean) => {
  buyMoneyBagState.bagTip = state;
});

export const setAmount = action((num: number) => {
  const amount = buyMoneyBagState.amount + num;

  if (amount <= 0) return;

  buyMoneyBagState.amount = amount;
});
