import { useEffect } from "react";
import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import { useSuiClient, useSuiClientQuery } from "@mysten/dapp-kit";
import rootStore, { walletStore } from "@/store";

let walletInterval: NodeJS.Timeout;

const WalletLayout = observer(({ children }: { children: React.ReactNode }) => {
  const address = walletStore.address;
  const client = useSuiClient();

  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#fff");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  const { data, isFetched } = useSuiClientQuery("getBalance", {
    owner: address as string,
  });

  useEffect(() => {
    rootStore.setLoading(true);
    if (isFetched) {
      rootStore.setLoading(false);
      walletStore.setBalance(data?.totalBalance as string);

      walletInterval = setInterval(() => {
        client
          .getBalance({
            owner: address as string,
          })
          .then((data) => {
            walletStore.setBalance(data?.totalBalance as string);
          })
          .catch(() => {
            console.log("error gettting balance");
          });
      }, 25000);
    }

    return () => {
      walletInterval && clearInterval(walletInterval);
    };
  }, [isFetched]);

  return (
    <div
      style={{
        background: "#fff",
      }}
      className={styles.layout}
    >
      {isFetched && children}
    </div>
  );
});

export default WalletLayout;
