import Backend from "./Backend";
import {
  ContentMessage,
  ContentResponse,
  GameOptions,
  SyncResponse,
} from "./types";
import ky from "ky";
import { delay, isDevelopment } from "@/utils";
import { gameStore, leagueStore } from "@/store";
import { settingsStage } from "@/constants";
import camelKeys from "camelcase-keys";

export default class FakeBackend extends Backend {
  public resync(): Promise<SyncResponse> {
    return this.sendSyncV3();
  }
  public sendWallet(hash: string, walletType: "STASHED" | "OKX"): void {
    console.log("sendWallet", hash, walletType);
  }

  public logoutWallet(): void {
    console.log("logoutWallet");
  }

  public getContext(): Promise<void> {
    console.log("getGoogleContext");
    return Promise.resolve();
  }
  public sendContext(hash: string, token: string): void {
    console.log("sendGoogleContext", hash, token);
  }
  constructor() {
    super();
  }

  async init() {
    this.initState = await this.sendSyncV3();
    if (isDevelopment) {
      this.options = await import("./localOptions.json");
    } else {
      const json = await ky.get(settingsStage + "/objects").json();
      await delay(0);
      const camel = camelKeys(json as Record<string, unknown>, {
        deep: true,
        stopPaths: ["game_objects.conditions"],
      });
      this.options = camel as unknown as GameOptions;
    }
    this.readyResolve!();
  }

  protected async send(message: ContentMessage): Promise<ContentResponse> {
    await delay(100);
    let data: SyncResponse | undefined;
    try {
      if (sessionStorage["fakeBackend"]) {
        data = JSON.parse(sessionStorage["fakeBackend"]);
      }
    } catch (e) {
      console.error("error loading fakeBackend");
      throw e;
    }
    if (!data) {
      data = this.newDevGameState();
    }

    switch (message.action) {
      case "tap":
        data.balance = gameStore.balance;
        data.totalBalance = gameStore.totalBalance;
        data.energy = gameStore.energy;
        data.leagueLevel = leagueStore.level;
        break;
      case "sync":
        //console.log("sync");
        break;
      case "sync_v2":
        //console.log("sync");
        break;
      case "boost":
        {
          if (message.boostId == 1) {
            data.maxFoodStockSizeBoost = message.level;
          }
          if (message.boostId == 2) {
            data.restockSpeedBoost = message.level;
          }
          if (message.boostId == 3) {
            data.portionSizeBoost = message.level;
          }
          if (message.boostId == 6) {
            data.autoFeedBotIsOn = Boolean(message.level);
          }
        }
        break;
      case "mission": {
        await delay(2000);
        const id = message.missionId;
        data.missions.push(id);
        sessionStorage["fakeBackend"] = JSON.stringify(data);
        return {
          isCompleted: true,
          missionId: id,
        };
      }
      case "friend_v2": {
        await delay(2000);
        return {
          friends: Array.from({ length: 20 }).map(() => ({
            username: "Сын маминой подруги",
            leagueLevel: Math.floor(Math.random() * 5),
          })),
          hasMore: true,
          totalCount: 100,
        };
      }
      case "league": {
        await delay(2000);
        return {
          id: 1,
          players: Array.from({ length: 100 }).map((_, i) => ({
            position: i + 1,
            username: "Сын маминой подруги " + i,
            coins: i + 1 * 100000,
            img: "",
          })),
          league: 1,
          playerPosition: 1,
          countOfLeagueParticipants: 100000,
          playerCoins: 100000,
        };
      }

      default:
        console.error("message unknown action");
    }
    if (message.action != "sync_v2") {
      data.lastSyncTimestamp = this.timestamp;
    }
    sessionStorage["fakeBackend"] = JSON.stringify(data);
    return data;
  }

  newDevGameState(): SyncResponse {
    return {
      balance: 9999999,
      totalBalance: 999999,
      energy: 100,
      lastSyncTimestamp: Math.trunc(Date.now() / 1000),
      maxFoodStockSizeBoost: 0,
      restockSpeedBoost: 0,
      portionSizeBoost: 0,
      instantRecoveryBoost: { attempts: 3, lastUseTimestamp: null },
      turboBoost: { attempts: 3, lastUseTimestamp: null },
      missions: [],
      leagueLevel: 0,
      earnedReferralBalance: 0,
      autoFeedBotIsOn: false,
      autoFeedBotIncome: 0,
      isEarly: false,
      wallet:
        "0xa42459225e68f9c655cb3ab75a8b487726463726f14c163ebd0cb3ba5ea8d05d",
      walletType: "STASHED",
      openBagsCount: 0,
      nft: {
        coins: 100,
        leagueLvl: 0,
        address:
          "0x4d170b67dd4c6f6782b174801415bf6b8f5e7c29f623171a31030320923013cf",
        createdAt: 1730831602,
        updatedAt: 1731177849,
        owner:
          "0x4d170b67dd4c6f6782b174801415bf6b8f5e7c29f623171a31030320923013cf",
      },
    };
  }
}
