import { FC } from "react";
import { observer } from "mobx-react-lite";
import Info from "@/assets/layout/rating/info.svg?react";
import capyBag from "@/assets/capybara-images/capybag-shine.png";
import Star1 from "@/assets/icons/star1.svg?react";
import Star2 from "@/assets/icons/star2.svg?react";
import { Button } from "@/components/Button";

import styles from "./styles.module.sass";
import { DialogComponents } from "@/components/Dialog";

type OpenMoneyBagProps = {
  isOpen: boolean;
  onAction: VoidFunction;
  onClose: VoidFunction;
  onListForSale: VoidFunction;
};

export const OpenMoneyBag: FC<OpenMoneyBagProps> = observer(
  ({ isOpen, onAction, onClose, onListForSale }) => {
    return (
      <>
        <DialogComponents.Container
          isOpen={isOpen}
          onClose={onClose}
          onAction={onAction}
          overlayClassName={styles.modalOverlay}
          wrapperClassName={styles.modalContentWrapper}
        >
          {({ handleClose, handleAction }) => (
            <DialogComponents.Content className={styles.modalContent}>
              <DialogComponents.Closer
                className={styles.modalCloser}
                onClick={handleClose}
              />
              <div className={styles.capybag}>
                <Star1 className={styles.star} />
                <Star2 className={styles.star} />
                <img
                  alt="capybag"
                  src={capyBag}
                  style={{ maxWidth: "323px", width: "100%" }}
                />
              </div>
              <DialogComponents.TextContainer className={styles.textContainer}>
                <DialogComponents.Title className={styles.modalTitle}>
                  {`You got a Money Bag!`}
                </DialogComponents.Title>
                <DialogComponents.Text className={styles.modalText}>
                  {`You can sell it on NFT marketplace or open it for a chance to multiply your coins balance before the BIG EVENT!`}
                </DialogComponents.Text>
                <div className={styles.feeContainer}>
                  <div className={styles.feeInfo}>
                    <p>Transaction Fee</p>
                  </div>
                  <div className={styles.feeInfoCpntaier}>
                    <p className={styles.amount}>
                      <div>~</div>0.002 SUI
                    </p>
                    <Info />
                  </div>
                </div>
              </DialogComponents.TextContainer>
              <div className={styles.controls}>
                <Button
                  onClick={handleAction}
                  label="Open Money Bag"
                  styleType="primary"
                  block={true}
                />
                <Button
                  onClick={onListForSale}
                  label="List for Sale"
                  styleType="outline"
                  block={true}
                />
              </div>
            </DialogComponents.Content>
          )}
        </DialogComponents.Container>
      </>
    );
  }
);
