import { FC } from "react";
import { observer } from "mobx-react-lite";

import capybara from "./animation/capybara.lottie.json";
import { Button } from "@/components/Button";
import styles from "./styles.module.sass";
import { DialogComponents } from "@/components/Dialog";
import Lottie from "lottie-react";

type OpenMoneyBagSucessProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAction: VoidFunction;
  reward: number;
};

export const OpenMoneyBagSucess: FC<OpenMoneyBagSucessProps> = observer(
  ({ isOpen, onClose, onAction, reward }) => {
    return (
      <DialogComponents.Container
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
        isOpen={Boolean(isOpen)}
        onAction={onAction}
        onClose={onClose}
      >
        {({ handleClose }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              className={styles.modalCloser}
              onClick={handleClose}
            ></DialogComponents.Closer>
            <div className={styles.capybag}>
              <Lottie
                loop={true}
                animationData={capybara}
                style={{
                  maxWidth: "311px",
                }}
              />
            </div>
            <DialogComponents.TextContainer className={styles.textContainer}>
              <DialogComponents.Title className={styles.modalTitle}>
                {reward < 5000000 ? `Money Bag Opened!` : `Congratulations!`}
              </DialogComponents.Title>
              <DialogComponents.Text className={styles.modalText}>
                <span style={{ lineHeight: "20px" }}>
                  <p>
                    You’ve unlocked
                    <span style={{ fontWeight: 700, paddingTop: "6px" }}>
                      {" "}
                      {reward.toLocaleString("en-US")} coins!
                    </span>{" "}
                    🎉💰{" "}
                    {reward < 5000000 ? (
                      <>
                        Every coin counts toward boosting your balance for the{" "}
                        <span
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          Big Event!
                        </span>{" "}
                      </>
                    ) : (
                      `You’re one step closer to maximizing your BIG EVENT
                    rewards!🚀`
                    )}
                  </p>
                  <p style={{ fontWeight: 700, paddingTop: "6px" }}>
                    The more bags you open, the bigger your reward for the BIG
                    EVENT! 💰✨
                  </p>
                </span>
              </DialogComponents.Text>
            </DialogComponents.TextContainer>
            <div style={{ width: "100%" }}>
              <Button
                onClick={handleClose}
                label="Got it!"
                styleType="primary"
                block={true}
              ></Button>
            </div>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
    );
  }
);
