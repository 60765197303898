import { FC } from "react";
import { observer } from "mobx-react-lite";

import capyBag from "@/assets/capybara-images/capybag-shine.png";
import { Button } from "@/components/Button";
import capybaraMultipilers from "@/assets/capybara-images/MulripliersInfo.png";
import styles from "./styles.module.sass";
import { DialogComponents } from "@/components/Dialog";

type MoneyBagTipProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAction: VoidFunction;
};

export const MoneyBagTip: FC<MoneyBagTipProps> = observer(
  ({ isOpen, onClose, onAction }) => {
    return (
      <DialogComponents.Container
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
        isOpen={Boolean(isOpen)}
        onAction={onAction}
        onClose={onClose}
      >
        {({ handleClose }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              className={styles.modalCloser}
              onClick={handleClose}
            ></DialogComponents.Closer>
            <div className={styles.capybag}>
              <img alt="capybag" src={capyBag} style={{ maxWidth: "183px" }} />
            </div>
            <DialogComponents.TextContainer className={styles.textContainer}>
              <DialogComponents.Title className={styles.modalTitle}>
                {`Unlock the mystery!`}
              </DialogComponents.Title>
              <DialogComponents.Text className={styles.modalText}>
                <span style={{ lineHeight: "20px" }}>
                  <p>
                    Open a Money Bag to reveal hidden rewards. Each Money Bag
                    contains{" "}
                    <span style={{ fontWeight: 700 }}>
                      up to 15,000,000 in-game coins.
                    </span>{" "}
                    💰✨
                  </p>
                  <p style={{ fontWeight: 700, paddingTop: "6px" }}>
                    The more bags you open, the bigger your reward for the BIG
                    EVENT! 💰✨
                  </p>
                  <img
                    style={{
                      width: "100%",
                      paddingTop: "6px",
                    }}
                    src={capybaraMultipilers}
                    alt=""
                  />
                  <p>Start unlocking now and grow your rewards! </p>
                </span>
              </DialogComponents.Text>
            </DialogComponents.TextContainer>
            <div style={{ width: "100%" }}>
              <Button
                onClick={handleClose}
                label="Got it!"
                styleType="primary"
                block={true}
              ></Button>
            </div>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
    );
  }
);
